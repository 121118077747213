import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import PrivateRoute from './Utils/PrivatRoute';
import PublicRoute from './Utils/PublicRoute';
// import { OkmonkInit } from './Utils/FuctionUtil';

// import AllResources from './Pages/Admin/AllResources';
// import EditResource from './Pages/Admin/EditResource';
// import Login from './Pages/Auth/Login';
// import MyAppBar from './Layout/MyAppBar';
// import Registration from './Pages/Auth/Registration';
// import ResetPass from './Pages/Auth/ResetPass';
// import MyUpl from './Pages/Admin/MyUpl';
import Home from './Pages/Home/Home';

const AllResources = lazy(() => import('./Pages/Admin/AllResources'));
const EditResource = lazy(() => import('./Pages/Admin/EditResource'));
const Login = lazy(() => import('./Pages/Auth/Login'));
const MyAppBar = lazy(() => import('./Layout/MyAppBar'));
const Registration = lazy(() => import('./Pages/Auth/Registration'));
const ResetPass = lazy(() => import('./Pages/Auth/ResetPass'));
const MyUpl = lazy(() => import('./Pages/Admin/MyUpl'));



const App = () => {




	return (
		<>

			<BrowserRouter>
				<Suspense fallback={
					<div>
						<div class="preloader-it">
							<div class="loader-pendulums"></div>
						</div>
					</div>}
				>
					<Switch>
						{/* <Route exact path="/" component={Home} /> */}
						<Route exact path="/" component={Home} />
						<PrivateRoute path="/uploadresource" component={MyUpl} />
						<PrivateRoute path="/allresources" component={AllResources} />
						<PrivateRoute path="/editresource/:id" component={EditResource} />
						<PublicRoute path="/register" component={Registration} />
						<Route path="/appbar" component={MyAppBar} />
						<PublicRoute path="/login" component={Login} />
						<PublicRoute exact path="/resetpass" component={ResetPass} />
					</Switch>
				</Suspense>
			</BrowserRouter>
		</>
	)
}

export default App