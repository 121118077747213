import React from 'react';
import './index.css';
import './assets/dist/css/style.css';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App';
import firebase from 'firebase/compat/app';

// < initializing a firebase >
firebase.initializeApp({
  apiKey: "AIzaSyBt6dGy0fKKq6bVxY3A9enEhVMPuHN1JIw",
  authDomain: "okmonk-backend-b2a51.firebaseapp.com",
  projectId: "okmonk-backend-b2a51",
  storageBucket: "okmonk-backend-b2a51.appspot.com",
  messagingSenderId: "670551896472",
  appId: "1:670551896472:web:846de45269f5a8e111fbed"
  
});

//< /initializing a firebase >

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();