 
   
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    sessionStorage.removeItem('UserId');
   }
   
  // set useId to session storage

  export const setUserId = (UserId) =>{
      sessionStorage.setItem("UserId",UserId);
  }

  // return the use id from the session storage

  export const getUserId = () =>{
      return sessionStorage.getItem('UserId');
  }