import * as $ from 'jquery';
   export const toggleNavMenu = () => {
    /*Variables*/
    var $wrapper = $(".hk-wrapper");


    /*Togglable Js*/
    $(document).on('click', '.hk-navbar-togglable', function (e) {
        $wrapper.toggleClass('hk-navbar-toggle');
        $(this).find('.feather-icon').toggleClass('d-none');
        return false;
    });

    /*Nav sidebar Toggle*/
    $(document).on('click', '.navbar-toggler-menu', function (e) {
        $wrapper.toggleClass('hk__menu__toggle');
        $(window).trigger("resize");
        return false;
    });
    $(document).on('click', '#hk_menu_backdrop,#hk_menu_close', function (e) {
        $wrapper.removeClass('hk__menu__toggle');
        return false;
    });
    var $targetCollapse = $('.hk-navbar .navbar-collapse');
    $('.hk-navbar .primary-nav-wrap .dropdown').on('show.bs.dropdown', function () {
        if (($targetCollapse).hasClass('show'))
            $targetCollapse.collapse("hide");
    });
    $('.hk-navbar .primary-nav-wrap .btn,#navbar_toggle_btn').on('click', function () {
        if (($targetCollapse).hasClass('show'))
            $targetCollapse.collapse("hide");
    });


}

/* Init functions */
export const OkmonkInit = () => {

    /*****Ready function start*****/
    $(document).ready(function () {
        okmonk();
        /*Feather Icon*/
        // var featherIcon = $('.feather-icon');
        // if( featherIcon.length > 0 ){
        // 	feather.replace();
        // }
        /*Subscription function call*/
        var $form = $('#mc_embedded_subscribe_form');
        if ($form.length > 0) {
            $(document).on("click", '#mc_embedded_subscribe_form button[type="submit"]', function (e) {
                if (e) e.preventDefault();
                register($form);
            })
        }

        /*Contact Form*/
        var form = $('.hk-feedback-form'),
            message = $('.feedback-message'),
            form_data;

        // Success function
        function done_func(response) {
            message.fadeIn().removeClass('alert-danger,alert-success').addClass('alert-success');
            message.text(response);
            setTimeout(function () {
                message.fadeOut();
            }, 2000);
            form.find('input:not([type="submit"]), textarea').val('');
            //alert("success");
        }

        // fail function
        function fail_func(data) {
            message.fadeIn().removeClass('alert-danger,alert-success').addClass('alert-danger');
            message.text(data.responseText);
            setTimeout(function () {
                message.fadeOut();
            }, 2000);
            //alert("fail");
        }

        form.submit(function (e) {
            e.preventDefault();
            form_data = $(this).serialize();
            $.ajax({
                type: 'POST',
                url: form.attr('action'),
                data: form_data
            })
                .done(done_func)
                .fail(fail_func);
        });
    });
    /*****Ready function end*****/



    /*Variables*/
    // var $wrapper = $(".hk-wrapper");
    // $nav = $(".hk-nav"),
    // $vertnaltNav = $(".hk-wrapper.hk-vertical-nav,.hk-wrapper.hk-alt-nav"),
    // $horizontalNav = $(".hk-wrapper.hk-horizontal-nav"),
    // $navbar = $(".hk-navbar");

    /***** Subscription function start *****/
    var register = function ($form) {
        $('#mc_embedded_subscribe').val('Sending...');
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            cache: false,
            dataType: 'json',
            contentType: 'application/json; charSet=utf-8',
            error: function (err) { alert('Could not connect to the registration server. Please try again later.') },
            success: function (data) {
                $('#mc_embedded_subscribe').val('subscribe')
                if (data.result === 'success') {
                    // Yeahhhh Success
                    console.log(data.msg)
                    $('#subscribe_result').html('<p>Thank you for subscribing. We have sent you a confirmation email.</p>')
                    $('#mce_email').val('');
                    $(window).trigger("resize");
                } else {
                    // Something went wrong, do something to notify the user.
                    console.log(data.msg)
                    $('#subscribe_result').html('<p>' + data.msg.substring(4) + '</p>');
                    $(window).trigger("resize");
                }
            }
        })
    };
    /***** Subscription function end *****/

    /***** okmonk function start *****/
    var okmonk = function () {
        if ($('#copyright_year').length > 0) {
            document.getElementById('copyright_year').appendChild(document.createTextNode(new Date().getFullYear()))
        }
       

    };
    /***** okmonk function end *****/

    /***** Full height function start *****/
    var height, width
    var onResizeFunction = function () {
        height = window.innerHeight;
        width = window.innerWidth;
        $('.hk-pg-wrapper').css('min-height', (height));
        $('.full-height').css('height', (height));
        $('.hk-pg-wrapper').css('padding-bottom', ($('.hk-footer-wrap').outerHeight()));
        if ($('.isolayer--scroll1').length > 0)
            $('.isolayer--scroll1').css('width', (width));

        /*Clamp Text*/

        $('.card-text').each(function (i, e) {
            $(this).addClass("clamp-text-" + i);
            setTimeout(function () {
                $($(".clamp-text-" + i).get(0), { clamp: 2 });
            }, 80);
        })
    };
    /***** Full height function end *****/

    /***** Resize function start *****/
    $(window).on("resize", function () {
        onResizeFunction();
    });
    $(window).trigger("resize");
    /***** Resize function end *****/

}


export const searchCollapse = () => {

    /*Variables*/
    var $wrapper = $(".hk-wrapper");
    /*Search form Collapse*/
    $(document).on('click', '#navbar_search_btn', function (e) {
        e.preventDefault();
        if ($wrapper.hasClass('navbar-search-toggle')) {
            $(".filtr-search").blur();
            $wrapper.removeClass('navbar-search-toggle');
        }
        else {
            $(".filtr-search").focus();
            $wrapper.addClass('navbar-search-toggle');
        }
    });


}

export const ovelayMenu = () => {
    /*Variables*/
    var $wrapper = $(".hk-wrapper");
     /*Overlay Menu*/
    $(document).on("click", '.hk-menu-trigger', function (e) {
        $('#hk_overlay_menu').addClass('open');
        $wrapper.addClass('overlay-menu-open');
    });

    $(document).on("click", '.hk-overlay-close,.nav-item ', function (e) {
        $('#hk_overlay_menu').removeClass('open');
        $wrapper.removeClass('overlay-menu-open');
    });

     
}

export const loadFunction = () => {
    /*****Load function start*****/
    $(window).on("load", function () {
        $(".preloader-it").delay(100).fadeOut("slow");
    });
    /*****Load function* end*****/
}
  